<template>
    <div>

        <!-- announcement bar block starts here-->
        <div class="announcementBar" 
            v-if="storeAnnouncementBar.length > 0" 
            @mouseenter="handleMouseEnter('announcement-bar')" 
            @mouseleave="handleMouseLeave('announcement-bar')"
        >
            <div class="db-container">
                <swiper 
                    :loop="storeAnnouncementBar.length > 1" 
                    :autoplay="{delay:3000}" 
                    :slides-per-view="1" 
                    :space-between="0" 
                    :speed="1000"
                    ref="announcementBarSwiper"
                >
                    <swiper-slide v-for="(storeAnnouncement,index) in storeAnnouncementBar" :key="`announcement-${index}`">
                        <div class="swiper-data">
                            <div class="swiper-content" v-html="storeAnnouncement.announcement.description"></div>
                            <loaderBtn :stroke="'#fff'" v-if="deletingAnnouncement && deletingAnnouncement.id == storeAnnouncement.id && storeAnnouncementLoader"/>
                            <button 
                                type="button" 
                                v-else-if="storeAnnouncement.announcement.closable == 1" 
                                @click.prevent="acknowledgeAnnouncementHandle(storeAnnouncement)"
                            >
                                <svg enable-background="new 0 0 32 32" id="Слой_1" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" id="Close"/>
                                    <g/><g/><g/><g/><g/><g/>
                                </svg>
                            </button>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <!-- announcement bar block ends here-->

        <!-- announcement popup block starts here-->
        <div class="modalBg categoryResponsiveModal announcementModal" :class="checkAnnouncementPopups ? 'modal-active' : ''">
            <div class="modalLayers"></div>
            <div class="modalBody" style="width: 500px;">
                <div 
                    class="oderSlideBox productSideBox" 
                    style="position: relative; right: 0; width: 100%" 
                    @mouseenter="handleMouseEnter('announcement-popup')" 
                    @mouseleave="handleMouseLeave('announcement-popup')"
                >
                    <swiper 
                        :loop="storeAnnouncementPopup.length > 1" 
                        :autoplay="{delay:3000}" 
                        :slides-per-view="1" 
                        :space-between="0" 
                        :speed="1000"
                        ref="announcementPopupSwiper"
                    >
                        <swiper-slide v-for="(storeAnnouncement,index) in storeAnnouncementPopup" :key="`announcement-popup-${index}`">
                            <div class="swiper-data popup">
                                <h5>{{ storeAnnouncement.announcement.heading  }}</h5>
                                <div class="swiper-content popup" v-html="storeAnnouncement.announcement.description"></div>
                                <loaderBtn :stroke="'#fff'" v-if="deletingAnnouncement && deletingAnnouncement.id == storeAnnouncement.id && storeAnnouncementLoader"/>
                                <button 
                                    type="button" 
                                    v-else-if="storeAnnouncement.announcement.closable == 1" 
                                    @click.prevent="acknowledgeAnnouncementHandle(storeAnnouncement)"
                                >
                                    Acknowledge
                                </button>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="modalCloseIcon">
                    <button type="button" @click.prevent="closeAnnouncementPopupHandle()">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 14.026 14.027"
                        >
                            <path
                                id="cross-small"
                                d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z"
                                transform="translate(-5.483 -5.483)"
                                fill="#fd4d5d"
                                stroke="#fd4d5d"
                                stroke-width="0.3"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <!-- announcement popup block ends here-->

    </div>
</template>

<script>
import loaderBtn from "@/components/loaderBtn.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import { MessageBox, Message, Loading } from 'element-ui'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide, } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Autoplay])

export default {
    name: "Announcements",
    data() {
        return {
            //announcements start
            storeAnnouncementBar:[],
            storeAnnouncementPopup:[],
            storeAnnouncementLoader:false,
            deletingAnnouncement:null,
            //announcements end
        }
    },
    props:{

    },
    components:{
        loaderBtn,
        
        Swiper,
        SwiperSlide,
    },
    computed:{
        ...mapGetters({
            settings: "settings_module/settings",
            settingsLoader: "settings_module/isPending",
            logoutIsPending:'logoutIsPending',
            isAnnouncementPopupClosed:'settings_module/announcementPopupStatus',
        }), 
        checkAnnouncementPopups(){

            if((this.storeAnnouncementPopup.length > 0) && !this.isAnnouncementPopupClosed){

                return true;

            }else{

                return false;

            }

        },
    },
    watch:{
        settings:{
            handler:function(value){

                if(value && value.store_announcements && value.store_announcements.length > 0){

                    let tempAnnouncementBars = [];

                    let tempAnnouncementPopup = [];

                    value.store_announcements.forEach((storeAnnouncement) => {

                        if(storeAnnouncement.announcement.type == 'announcement_bar'){

                            tempAnnouncementBars.push(storeAnnouncement);

                        }else if(storeAnnouncement.announcement.type == 'popup'){

                            tempAnnouncementPopup.push(storeAnnouncement);

                        }

                    });

                    this.storeAnnouncementBar = _.cloneDeep(tempAnnouncementBars);

                    this.storeAnnouncementPopup = _.cloneDeep(tempAnnouncementPopup);

                }

            },deep:true
        },
    },
    methods:{
        //announcement handling methods start
        acknowledgeAnnouncementHandle(listedAnnouncement){

            MessageBox.confirm(
                'Do you really want to remove the announcement? Acknowledging the statement made in the announcement can positively impact your experience.',
                'Warning',
                {
                    type: 'warning',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                }
            ).then(async ()=>{

                let formData = new FormData();

                formData.append('store_announcement_id',listedAnnouncement.id);

                this.deletingAnnouncement = listedAnnouncement;

                this.storeAnnouncementLoader = true
                try{
                    let res = await this.$axios.post('/announcement/acknowledge',formData);
                    if(res.data.status_code == "1806"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });
                        
                        this.storeAnnouncementBar = [];

                        this.storeAnnouncementPopup = [];

                        this.$store.dispatch("settings_module/fetchStoreSetting");

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message, 
                    });

                }finally{

                    this.deletingAnnouncement = null;

                    this.storeAnnouncementLoader = false;

                }

            }).catch(() => {})

        },
        handleMouseEnter(announcementType){

            if(announcementType == 'announcement-bar'){

                this.$refs.announcementBarSwiper.$el.swiper.autoplay.stop();

            }else if(announcementType == 'announcement-popup'){

                this.$refs.announcementPopupSwiper.$el.swiper.autoplay.stop();
            
            }

        },
        handleMouseLeave(announcementType){

            if(announcementType == 'announcement-bar'){

                this.$refs.announcementBarSwiper.$el.swiper.autoplay.start();

            }else if(announcementType = 'announcement-popup'){

                this.$refs.announcementPopupSwiper.$el.swiper.autoplay.start();

            }

        },
        closeAnnouncementPopupHandle(){

            MessageBox.confirm(
                'Do you really want to close the announcement popup?. You can acknowledge all closable announcements or close for now.',
                'Warning',
                {
                    type: 'warning',
                    confirmButtonText: 'Acknowledge',
                    cancelButtonText: "Don't show again",
                    customClass: "popupConfirmation",
                    beforeClose: async (action, instance, done) => {

                        if(action === 'confirm'){

                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = 'Loading...';

                            let announcementList = [];

                            this.storeAnnouncementPopup.forEach((listedAnnouncement) => {

                                announcementList.push(listedAnnouncement.id);

                            });

                            let formData = new FormData();

                            formData.append('store_announcements',JSON.stringify(announcementList));

                            try{
                                let res = await this.$axios.post('/announcement/acknowledge/bulk',formData);
                                if(res.data.status_code == "1806"){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    this.storeAnnouncementBar = [];

                                    this.storeAnnouncementPopup = [];

                                    this.$store.dispatch("settings_module/fetchStoreSetting");

                                }
                            }catch(error){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response ? error.response.data.message : error.message,
                                });

                            }finally{

                                instance.confirmButtonLoading = false;
                                instance.confirmButtonText = 'Acknowledge';

                                done();

                            }

                        }else{

                            this.$store.commit("settings_module/update_announcement_popup_close",true);

                            done();

                        }

                    }

                }

            ).then(async ()=>{ 
            
            }).catch(() => {})

        },
        //announcement handling methods end

    },
    mounted(){
    },
    beforeMount(){

        if(this.settings){
            
            if(this.settings.store_announcements && this.settings.store_announcements.length > 0){

                let tempAnnouncementBars = [];

                let tempAnnouncementPopup = [];

                this.settings.store_announcements.forEach((storeAnnouncement) => {

                    if(storeAnnouncement.announcement.type == 'announcement_bar'){

                        tempAnnouncementBars.push(storeAnnouncement);

                    }else if(storeAnnouncement.announcement.type == 'popup'){

                        tempAnnouncementPopup.push(storeAnnouncement);

                    }

                });

                this.storeAnnouncementBar = _.cloneDeep(tempAnnouncementBars);

                this.storeAnnouncementPopup = _.cloneDeep(tempAnnouncementPopup);

            }
    
        }

    },
}   
</script>

<style>

</style>